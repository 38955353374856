import fullpage from 'fullpage.js'

new fullpage('#fullpage', {
    licenseKey: 'FA583F01-A4FA44BA-B2A8A19B-423F2423',
    responsiveWidth: 768,
    responsiveHeight: 500,
    afterLoad: function(origin, destination, direction){
        if (destination.item.id == 'section-video') {
            $('#hero-video').unbind('ended');
        }

        if (destination.item.id == 'section-welcome') {
            var echidna = document.getElementsByClassName("echidna");
            echidna.item(0).classList.add('active');
        }

     },
     onLeave: function(origin, destination, direction){
        if (destination.item.id == 'section-video') {
            $('#hero-video')
                .show()
                .trigger("play")
                .bind('ended', function () {
                    console.log('onLeave: binding.')
                    this.currentTime = 0;
                    this.pause();
                });
        }

        if (origin.item.id == 'section-welcome') {
            var echidna = document.getElementsByClassName("echidna");
            echidna.item(0).classList.remove('active');
        }
    }
});
